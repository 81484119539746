<template>
  <Section>
    <Container>
      <div class="grid">
        <Input id="designation" label="Nom du rapport" required />
      </div>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'TitleAddSubview',
  components: {
    Container,
    Section,
    Input,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().required(),
    })

    // init du form
    const { errors, isSubmitting } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
      method: 'post',
      endpoint: null,
      essai: {},
    }
  },
  emits: ['set-step', 'set-data', 'set-route'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    this.endpoint = `essai/${this.$route.params.id}/rapport`

    this.$emit('set-step', 0)

    if (this.$route.params.rid) {
      this.method = 'put'
      this.endpoint += `/${this.$route.params.rid}`
      this.loadData()
    }
  },
  methods: {
    loadData() {
      this.emitter.emit('open-loader')

      this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}`)
        .then(
          (response) => {
            this.essai = response.data
            this.$emit('set-data', this.essai)

            const schema = this.formService.populateShema(this.essai)

            this.formService.setFormValues(schema)
            this.emitter.emit('close-loader')
          },
          () => {
            this.emitter.emit('close-loader')
          },
        )
    },
    handleValues(values) {
      this.emitter.emit('open-loader')

      this.fetchService[this.method](this.endpoint, values).then(
        (response) => {
          const params = {
            id: this.$route.params.id,
            rid: response.data.id,
          }
          this.$router.push({
            name: 'essaiFixedReportModalitiesAdd',
            params,
          })

          this.emitter.emit('close-loader')
        },
        (error) => {
          this.emitter.emit('close-loader')
          this.formService.handleApiError(error)
        },
      )
    },
  },
}
</script>
